import React from "react";
import { inject, observer } from "mobx-react";
import { useSnackbar } from "notistack";
import dynamic from "next/dynamic";
import styled from "@emotion/styled";

import { palette } from "src/themes/palette";
import { IStore } from "src/stores/Store";
import { IAuthStore } from "src/stores/AuthStore";
import { IWalletStore } from "src/stores/WalletStore";
import OTPForm from "src/components/Form/OTPForm";
import MaterialButton from "src/components/Button/MaterialButton";
import FindOTPDialog from "src/components/Dialog/FindOTPDialog";
import { delay } from "src/utils/common";
import Snackbar from "src/components/Snackbar/Snackbar";
import { ErrorCode, errorMessageByCode } from "src/libs/error";
import MaterialIcon from "src/components/Icon/MaterialIcon";
import { format } from "src/utils/string";
import { makeQueryParams } from "src/utils/uri";
import MaterialModal from "src/components/Dialog/MaterialModal";
import { translate } from "src/locales";
import { useTranslation } from "next-i18next";
import { LoginStatus } from "src/__generate__/api";
import { storage as localStorage } from "src/libs/localStorage";
import { getAccountLoginPath } from "src/utils/common/pathHandlers";
import { useRouter } from "next/router";

type Params = {
  redirect?: string;
};

type Inject = {
  authStore: IAuthStore;
  walletStore: IWalletStore;
};

type Props = Inject;

const AccountLayout = dynamic(
  () => import("src/components/Layout/AccountLayout"),
  { ssr: false },
);

const CloseButton = styled(MaterialButton)`
  color: ${palette.bluegrey.dark};
  margin-left: -24px;
`;

export const getAccountOtpRegisterPath = (params: Params) =>
  `/account/otp/register${makeQueryParams(params)}`;

const AccountOtpRegister = inject(
  ({ store }: { store: IStore }): Inject => ({
    authStore: store.authStore,
    walletStore: store.walletStore,
  }),
)(
  observer((props: Props) => {
    const router = useRouter();
    const params = (router.query ?? {}) as Params;

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [dialog, setDialog] = React.useState<React.ReactNode | null>(null);

    React.useEffect(() => {
      const { isLogin } = props.authStore;
      // _app에서 store.initialize에서 에러 발생 시 로그인 페이지로 redirect하는 로직과 충돌이 일어나 무한 새로고침이 발생해서 이 부분 개선 전까지 주석처리
      // if (this.props.authStore.serverAccessToken) {
      //   this.props.router.replace("/");
      //   return;
      // }
      if (!isLogin) {
        router.replace(getAccountLoginPath(params));
      }
    }, []);

    const closeDialog = () => {
      setDialog(null);
    };
    const showFindOTPDialog = () => {
      setDialog(<FindOTPDialog onClose={closeDialog} />);
    };

    const submit = async (otp: string) => {
      const { loginWithOtp, me } = props.authStore;
      const { email } = props.authStore.user;
      const { redirect } = params;
      try {
        const { loginStatus } = await loginWithOtp({ otp });
        if (loginStatus === LoginStatus.NeedIpVerification) {
          enqueueSnackbar(
            <Snackbar
              IconComponent={
                <MaterialIcon
                  name="warning"
                  fontSize={22}
                  color={palette.red.main}
                />
              }
              message={format(
                errorMessageByCode(ErrorCode.NOT_VERIFIED_IP, ""),
                {
                  email,
                },
              )}
            />,
          );
          return;
        }
        if (loginStatus === LoginStatus.NeedOtp) {
          enqueueSnackbar(
            <Snackbar
              IconComponent={
                <MaterialIcon
                  name="warning"
                  fontSize={22}
                  color={palette.red.main}
                />
              }
              message={errorMessageByCode(
                ErrorCode.OTP_AUTHENTICATION_FAILED,
                "",
              )}
            />,
          );
          return;
        }
        await me();
        localStorage().setIsPasswordExiredBefore(
          props.authStore.user.isPasswordExiredBefore,
        );
        window.location.replace(redirect ? redirect : "/");
        await delay(6000);
      } catch (error: any) {
        const errorStatus = error.status;
        if (errorStatus === ErrorCode.OTP_AUTHENTICATION_FAILED) {
          throw error;
        }
        enqueueSnackbar(<Snackbar message={error.message} />);
      }
    };

    return (
      <AccountLayout>
        <React.Fragment>
          <OTPForm
            submitName={translate(
              ["pages", "account", "otp", "register", "submitButton"],
              t,
            )}
            onSubmit={submit}
            ActionBottomLeftComponent={
              <CloseButton
                variant="text"
                color="primary"
                size={"large"}
                onClick={showFindOTPDialog}
              >
                {translate(
                  ["pages", "account", "otp", "register", "forgottenOtp"],
                  t,
                )}
              </CloseButton>
            }
          />
          <MaterialModal open={Boolean(dialog)} onClose={closeDialog}>
            {dialog}
          </MaterialModal>
        </React.Fragment>
      </AccountLayout>
    );
  }),
);

export default AccountOtpRegister;
