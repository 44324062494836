import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { WithTranslation, withTranslation } from "next-i18next";

import MaterialButton from "src/components/Button/MaterialButton";
import BottomButton from "src/components/Button/BottomButton";
import { spacing } from "src/themes/spacing";
import { palette } from "src/themes/palette";
import { H3Bold, Body1Regular } from "src/components/Typography/Typography";
import MaterialDialog from "src/components/Dialog/MaterialDialog";
import { translate } from "src/locales";

type Props = {
  onClose: () => void;
} & WithTranslation;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(H3Bold)``;

const Content = styled(Body1Regular)`
  color: ${palette.darkgrey.main};
  margin-top: ${spacing.small4};
`;

const Email = styled(Body1Regular)``;

const FindOTPDialog = ({ onClose, t }: Props) => {
  const navigateTo = useCallback(() => {
    window.open("mailto:support@kodax.com");
  }, []);

  return (
    <MaterialDialog
      size={"medium"}
      HeaderComponent={
        <Header>
          <Title>{translate(["findOTPDialog", "title"], t)}</Title>
          <Content>
            <div>{translate(["findOTPDialog", "description"], t)}</div>
            <MaterialButton
              variant="link_highlight"
              color={"primary"}
              onClick={navigateTo}
            >
              <Email>support@kodax.com</Email>
            </MaterialButton>
          </Content>
        </Header>
      }
      FooterComponent={
        <BottomButton
          buttonName={translate(["findOTPDialog", "submitButton"], t)}
          buttonProps={{
            variant: "outline",
            size: "large",
            onClick: onClose,
            isLoading: false,
          }}
        />
      }
    />
  );
};

export default withTranslation()(FindOTPDialog);
