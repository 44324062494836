import _ from "lodash";
import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import MaterialButton, { SizeType } from "src/components/Button/MaterialButton";
import { palette } from "src/themes/palette";
import { shouldForwardProp } from "src/themes/config";

export type Props<T> = {
  className?: string;
  data: Array<{
    label: React.ReactNode;
    value: T;
  }>;
  size: SizeType;
  onSelected: (value: T) => void;
  selected: T | null | undefined;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const getSizeBySizeType: Record<SizeType, number> = {
  small: 8,
  medium: 12,
  large: 12,
};

const ItemButton = styled(MaterialButton, {
  shouldForwardProp,
})<{
  $active: boolean;
  $isLast: boolean;
  $size: SizeType;
}>`
  ${({ $isLast, $size }) =>
    $isLast
      ? css``
      : css`
          margin-right: ${getSizeBySizeType[$size]}px;
        `}
  ${({ $active }) =>
    $active
      ? css`
          background-color: ${palette.bluegrey.pale};
        `
      : css`
          background-color: ${palette.white.main};
        `}
`;

function ToggleButton<T>(props: Props<T>) {
  const { className, data, size, onSelected, selected } = props;

  return (
    <Container className={className}>
      {data.map((item, index) => {
        const isLast = index === data.length - 1;
        return (
          <ItemButton
            $active={item.value === selected}
            $isLast={isLast}
            $size={size}
            key={index}
            size={size}
            variant="outline"
            onClick={_.partial(onSelected, item.value)}
          >
            {item.label}
          </ItemButton>
        );
      })}
    </Container>
  );
}

export default ToggleButton;
