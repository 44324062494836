import React from "react";
import styled from "@emotion/styled";

import { evaluationCSS } from "src/themes/evaluation";
import MaterialForm, {
  Props as MaterialFormProps,
} from "src/components/Form/MaterialForm";

export type Props = MaterialFormProps;

const MaterialDialog = styled(MaterialForm)`
  ${evaluationCSS.level5}
  border: unset;
`;

export default MaterialDialog;
