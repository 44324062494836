import React from "react";
import { Box, Modal, ModalProps } from "@mui/material";
import styled from "@emotion/styled";

import { isProduction } from "src/libs/env";

type Props = Omit<ModalProps, "children"> & { children: React.ReactNode };

const Container = styled(Modal)`
  outline: none;
  overflow: auto;
  top: ${isProduction ? 0 : 32}px !important;
  display: flex;
  height: 100%;
`;

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: auto;
`;

const MaterialModal = (props: Props) => {
  const { className, children, onClose, ...rest } = props;
  return (
    <Container
      {...rest}
      onClose={onClose}
      closeAfterTransition={true}
      disableEnforceFocus={true}
      disableAutoFocus={true}
    >
      <Content className={className}>{children}</Content>
    </Container>
  );
};

export default MaterialModal;
